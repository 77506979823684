@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "src/_metronic/assets/sass/components/_variables.custom.scss";

@import "../framework/styles/ui-builder.scss";
@import "../framework/styles/control-designer";
@import "../framework/styles/control-hover-menu";
@import "./background.scss";

.accordion-sm {
    .accordion-button {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-weight: 500;
    }
}

.form-control-xs {
    min-height: calc(0.5rem + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.925rem;
    border-radius: 0.425rem;
}

.form-check-xs {
    display: flex;
    align-items: center;
    padding-left: 0px;

    .form-check {
        display: inline-flex;
        align-items: center;
        margin: 0;
        margin-right: 1.5rem;
        padding: 0;
        cursor: pointer;
    }

    .form-check-input {
        margin-left: 0;
        margin-right: 0.5rem;
        width: 1rem;
        height: 1rem;
    }

    .form-check-input:checked + label {
        font-weight: 600;
    }
}

.form-control-sm {
    min-height: $input-height-sm;
    padding: $input-padding-y $input-padding-x;
    // @include font-size($input-font-size-sm);
    @include border-radius($input-border-radius-sm);
}

.form-select-sm {
    padding: $input-padding-y $input-padding-x;
    // padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
    // @include font-size($form-select-font-size-sm);
    @include border-radius($form-select-border-radius-sm);
}

.custom-typeahead {
    input {
        display: block;
        width: 100%;
        padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
        -moz-padding-start: subtract($form-select-padding-x, 3px); // See https://github.com/twbs/bootstrap/issues/32636
        font-family: $form-select-font-family;
        line-height: $form-select-line-height;
        background-image: escape-svg($form-select-indicator);
        background-repeat: no-repeat;
        background-position: $form-select-bg-position;
        background-size: $form-select-bg-size;
        @include border-radius($form-select-border-radius, 0);
        @include box-shadow($form-select-box-shadow);
        @include transition($form-select-transition);
        appearance: none;

        &:focus {
            border-color: $form-select-focus-border-color;
            outline: 0;
            @if $enable-shadows {
                @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
            } @else {
                // Avoid using mixin so we can pass custom focus shadow properly
                box-shadow: $form-select-focus-box-shadow;
            }
        }

        &[multiple],
        &[size]:not([size="1"]) {
            padding-right: $form-select-padding-x;
            background-image: none;
        }

        &:disabled {
            color: $form-select-disabled-color;
            background-color: $form-select-disabled-bg;
            border-color: $form-select-disabled-border-color;
        }

        // Remove outline from select box in FF
        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 $form-select-color;
        }
    }

    mark,
    .mark {
        padding: 0 0;
        background-color: var(--bs-highlight-bg);
    }
}

.typeahead-sm {
    input {
        @include font-size($form-select-font-size-sm);
        @include border-radius($form-select-border-radius-sm);
    }
    .dropdown-menu {
        @include font-size($form-select-font-size-sm);
    }

    .rbt-aux {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 1.25rem;
        height: 100%;
        margin-left: auto;
        padding: $form-select-padding-x;
    }

    .rbt-token,
    .rbt-input-wrapper {
        display: flex;
    }

    .rbt-token {
        background-color: $secondary;
        border-radius: 2px;
        color: $white;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-right: 0.5rem;
    }

    .rbt-close-content {
        display: none;
    }

    .btn-close {
        margin-left: 0.5rem;
    }
}

@import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
@import "ag-grid-community/styles/ag-theme-material.min.css";
@import "ag-grid-community/styles/ag-theme-alpine.min.css";
@import "ag-grid-community/styles/ag-theme-balham.min.css";

.ag-theme-material,
.ag-theme-alpine,
.ag-theme-balham {
    --ag-font-family: $font-family-sans-serif;
    --ag-font-size: $font-size-sm;
}

a.menu-link.active {
    background-color: $primary;
}

.menu-state-title-primary .menu-item .menu-link.active .menu-title {
    color: $primary-inverse !important;
}

.menu-state-title-primary .menu-item .menu-link.active {
    color: $primary-inverse !important;
}

.star-rating {
    button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 3rem;
        padding: 0 .25rem;
        margin: 0;
    }
    .on {
        color: $primary;
    }
    .off {
        color: #ccc;
    }
}
