.auth-card-responsive {
    width: 28%;
    display: flex;
    align-items: center;

    .logo {
        width: 50%;
        margin-bottom: 1rem;
    }

    @media (max-width: 768px) {
        width: 100%;
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

.background-image {
    background-image: url("../../public/media/background-images/background.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 7%;
    padding-left: 1%;
    position: relative;

    @media (max-width: 768px) {
        background-image: url("../../public/media/background-images/background-mobile.jpg");
        padding-top: 1rem;
        padding-left: 0;
    }
}

.mobile-logo {
    @media (max-width: 767.98px) {
        &::before {
            content: "";
            position: absolute;
            background-image: url("../../public/media/background-images/logo.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 160px;
            height: 54px;
            z-index: 10;
            top: 2rem;
            left: 4rem;
        }
    }

    @media (max-width: 912.98px) {
        &::before {
            content: "";
            position: absolute;
            background-image: url("../../public/media/background-images/logo.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 160px;
            height: 54px;
            z-index: 10;
            top: 2rem;
            left: 4rem;
        }
    }
}
